import './style.css'
import React, { Component } from 'react'
import classNames from 'classnames'
import { Container, Row, Col } from 'react-grid-system'
import { injectIntl, FormattedMessage } from 'react-intl'

import I18nLink from '../../../components/I18nLink'
import Space from '../../../components/Space'

const containerClass = classNames({
  'terms-content-container': true,
})

const TOU_URL = 'https://storage.googleapis.com/wuha-marketing/legal/cgu.pdf'
const TOU_SAAS_URL = 'https://storage.googleapis.com/wuha-marketing/legal/cgu-saas.pdf'

class Content extends Component {

  render() {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    const hereText = formatMessage({ id: 'terms.content.here' })

    return (
      <Container>
        <Row>
          <Col xs={12} md={10} offset={{ md: 1 }}>
            <div className={containerClass}>
              <h2>{formatMessage({ id: 'terms.content.company.title' })}</h2>
              <p>{formatMessage({ id: 'terms.content.company.rcs' })}</p>
              <p>{formatMessage({ id: 'terms.content.company.tva' })}</p>
              <p>{formatMessage({ id: 'terms.content.company.editor' })}</p>
              <Space type="m" />

              <h2>{formatMessage({ id: 'terms.content.hosting.title' })}</h2>
              <p>{formatMessage({ id: 'terms.content.hosting.street' })}</p>
              <p>{formatMessage({ id: 'terms.content.hosting.city' })}</p>
              <p>{formatMessage({ id: 'terms.content.hosting.country' })}</p>
              <Space type="m" />

              <h2>{formatMessage({ id: 'terms.content.cnil.title' })}</h2>
              <p>{formatMessage({ id: 'terms.content.cnil.description' })}</p>
              <Space type="m" />

              <h2>{formatMessage({ id: 'terms.content.tou.title' })}</h2>
              <FormattedMessage
                id="terms.content.tou.description"
                values={{
                  link: (
                    <a
                      href={TOU_URL}
                      title={formatMessage({ id: 'terms.content.tou.title' })}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {hereText}
                    </a>
                  ),
                }} />
              <Space type="m" />

              <h2>{formatMessage({ id: 'terms.content.saas.title' })}</h2>
              <FormattedMessage
                id="terms.content.saas.description"
                values={{
                  link: (
                    <a
                      href={TOU_SAAS_URL}
                      title={formatMessage({ id: 'terms.content.saas.title' })}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {hereText}
                    </a>
                  ),
                }} />
              <Space type="m" />

              <h2>{formatMessage({ id: 'terms.content.pp.title' })}</h2>
              <FormattedMessage
                id="terms.content.pp.description"
                values={{
                  link: (
                    <I18nLink to={'/privacy-policy'}>
                      {hereText}
                    </I18nLink>
                  ),
                }} />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default injectIntl(Content)
