import React from 'react'
import { injectIntl } from 'react-intl'

import withContext from '../components/withContext'
import injectContext from '../components/injectContext'
import withIntl from '../components/withIntl'
import Space from '../components/Space'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Navigation from '../scenes/Navigation'
import Header from '../scenes/Header'
import Content from '../scenes/Terms/Content'
import SecurityMini from '../scenes/SecurityMini'
import Footer from '../scenes/Footer'

const Terms = ({ intl: { formatMessage } }) => (
  <Layout>
    <SEO
      metaTitle={formatMessage({ id: 'meta.terms.title' })}
      metaDescription={formatMessage({ id: 'meta.terms.description' })}
    />
    <Navigation />
    <Header
      crumbs={[
        { name: formatMessage({ id: 'breadcrumb.wuha' }), path: '/' },
        { name: formatMessage({ id: 'breadcrumb.terms' }), path: '/terms' },
      ]}
      title={formatMessage({ id: 'terms.header.title' })}
      description={formatMessage({ id: 'terms.header.description' })}
    />
    <Space type="xl" />
    <Content />
    <Space type="giant" />
    <SecurityMini />
    <Footer />
  </Layout>
)

export default withContext(injectContext(withIntl(injectIntl(Terms))))
